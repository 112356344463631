.container-sm-width {
	margin-right: 2rem;
	margin-bottom: 1rem;
	margin-left: 2rem;
	margin-top: 1rem;
	background: rgb(250, 250, 250);
	border-radius: 15px;
	max-width: 750px;
	padding-bottom: 0.5rem;
}

.container-width {
	background: rgb(250, 250, 250);
	border-radius: 15px;
	min-width: 350px;
	max-width: 1800px;
	margin-bottom: 0;
	margin-right: 1rem;
	margin-left: 2rem;
	margin-top: 0;
}

.requiredLabel {
	font-size: 10px;
	margin-top: 0;
	margin-bottom: 0%;
}

.required::after {
	content: " *";
	font-weight: bold;
	color: red;
	display: inline-block;
}

.divRow {
	max-height: 30px;
}

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv,
.e-grid .e-gridheader .e-stackedheadercelldiv,
.e-detailheadercell,
.e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
	height: 50px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.e-gridheader.e-lib.e-droppable {
	padding-right: 0px;
	padding-left: 0px;
}
.button-custom {
	width: 75px;
	color: black;
}


.input-w input {
	float: none; /* if you had floats before? otherwise inline-block will behave differently */
	display: inline-block;
	vertical-align: left;
}

.input-w label {
	display: inline-block;
	vertical-align: middle;
}

.minHeight {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	overflow: hidden;
}

.e-custom {
	border-radius: 0;
}

.e-custom,
.e-custom:hover,
.e-custom:focus,
.e-custom:active {
	background-color: #ffda6f !important;
	color: black;
}

#gridTripStop_dialogEdit_wrapper .e-footer-content {
	visibility: collapse;
	height: 0;
}


div.e-gridheader.e-lib.e-droppable table div.e-headercelldiv{
	display: flex;
	justify-content: center;
	align-items: center;
}

/* form css  */
.capacity-board-form{
	border-radius: 10px;
	border: 1.3px solid #FFC43D;
	padding-bottom: 2rem;
	padding: 2rem;
}

/* for form fields  */
.capacity-form-fields{
	max-height: 45px;
	margin-right: 1.5rem;
	margin-bottom: 1.5rem;
	min-width: 17rem;
}