.login-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 2px;

	margin: 10px;
}

.div-wrapper {
	display: flex;
}

.button-custom {
	display: flex;
	align-items: left;
	justify-content: left;
}

.header-custom {
	color: black;
	background: #ffc43d;
	padding: 5px;
}

.custom-border {
	border-radius: 5px;
	border-color: #ffc43d;
	border-style: solid;
	margin-left: auto;
	margin-right: auto;
	width: 250px;
}

.label-wrapper {
	font-size: 12px;
	color: red;
}

.e-custom {
	border-radius: 0;
}

.e-custom,
.e-custom:hover,
.e-custom:focus,
.e-custom:active {
	background-color: #ffc43d;
	color: black;
}


.e-switch-wrapper.handle-text {
	width: 58px;
	height: 24px;
  }
  
  .e-switch-wrapper.handle-text .e-switch-handle {
	width: 26px;
	height: 20px;
	left: 2px;
	background-color: #fff;
	color: #ffc43d;
  }
  
  .e-switch-wrapper.handle-text .e-switch-inner,
  .e-switch-wrapper.handle-text .e-switch-handle {
	border-radius: 0;
  }
  
  .e-switch-wrapper.handle-text .e-switch-handle.e-switch-active {
	left: 46px;
  }
  
  .e-switch-wrapper.handle-text .e-switch-inner.e-switch-active,
  .e-switch-wrapper.handle-text .e-switch-inner.e-switch-active .e-switch-on {
	background-color: #ffc43d;
	border-color: #ffc43d;
  }
  
  .e-switch-wrapper.handle-text .e-switch-inner,
  .e-switch-wrapper.handle-text .e-switch-off {
	background-color: #ffc43d;
	border-color: #ffc43d;
  }
  
  .e-switch-wrapper.handle-text .e-switch-inner:after,
  .e-switch-wrapper.handle-text .e-switch-inner:before {
	font-size: 9px;
	font-weight: 600;
	position: absolute;
	line-height: 21px;
	font-family: "Nunito Sans", sans-serif;
	z-index: 1;
	height: 100%;
	transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
	

  }
  
  .e-switch-wrapper.handle-text .e-switch-inner:before {
	content: "EN";
	color: #ffc43d;

	left: 8px;
	top: 2px;
  }
  
  .e-switch-wrapper.handle-text .e-switch-inner:after {
	content: "FR";
	right: 11px;
	top:2px;
	color: black;
  }
  
  .e-switch-wrapper.handle-text .e-switch-inner.e-switch-active:before {
	color: #fafafa;
  }
  
  .e-switch-wrapper.handle-text .e-switch-inner.e-switch-active:after,
  .e-switch-wrapper.handle-text .e-switch-inner:before {
	color: black;
  }

  .e-switch-on {
	color: black;

  }
  e.e-switch-off{
	color: black;
  }

  .e-switch-wrapper.e-switch-wrapper.handle-text .e-switch-inner,
.e-switch-wrapper.e-switch-wrapper.handle-text .e-switch-off {
  background-color: #ffc43d;
  border-color: #ffc43d;
}

.e-switch-wrapper.handle-text .e-switch-handle {
  background-color: #fff;
}

.e-switch-wrapper.e-switch-wrapper.handle-text .e-switch-handle {
	background-color: white;
	color: black;
  }
  .e-switch-wrapper.e-switch-wrapper.handle-text .e-switch-handle {
	background-color: white;
  }

  .e-switch-wrapper.handle-text .e-switch-inner.e-switch-active:before {
    color: black;
}

.my-custom-btn {
	box-sizing: border-box;
	margin: 0;
	text-transform: none;
	display: inline-block;
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	user-select: none;
	border: 1px solid #ffc43d;
	border-radius: 4px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	cursor: pointer;
	color: #000;
	background-color: #ffc43d;
	border-color: #ffc43d;
}