.export-pdf:before {
	content: "\e240";
	background-color: white;
	font-size: small;
}

.download-file-link{
	font-size: 0.875em;
	color: #000;
	text-decoration: none;
}
.download-file-link:hover{
	color: #000;
}

.faq-container {
	max-width: 800px;
	margin: 0 auto;
}
  
.faq-list {
	list-style-type: disc;
	padding-left: 1.5em;
}

.faq-list li {
	margin-bottom: 1em;
}