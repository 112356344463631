.my-custom-sidebar {
    max-width: 250px !important;
}

div.offcanvas-body div.accordion.accordion-flush h2 button {
    background: transparent;
    border: none;
    box-shadow: none;
}


div.offcanvas-body div.accordion.accordion-flush div.accordion-body a,
div.offcanvas-body div.bottom-btns a
{
    padding: 10px;
    text-decoration: none;
    color: black;
}

.isDarkTheme div.offcanvas-body div.accordion.accordion-flush div.accordion-body a,
.isDarkTheme div.offcanvas-body div.bottom-btns a{
    color: white;
}

#root div.App-header nav button[aria-label="Toggle navigation"]{
    margin: 0 !important;
}

body .offcanvas-body a.custom-navitem.nav-link{
    color: black;
}

.isDarkTheme .offcanvas-body a.custom-navitem.nav-link{
    color: white;
}
/* 
div.offcanvas-body  a.NavLink__active.custom-navitem{
    padding-left: 1rem !important;
} */

.offcanvas-navbar {
    /* width: 200px; */
    height: 100%;
    z-index: 1;

    transition: width 0.3s ease-in-out;
    transform: translateX(-100%);
    position: fixed;
    border: none;
    top: 0;
    left: 0;
}

.offcanvas-navbar.show {
    transform: translateX(0%);
}

.offcanvas-navbar.show .dropdown-menu {
    width: 100%;
}

.offcanvas-navbar.show.selected {
    background-color: red;
}


.btn-link.active {
    color: red;
    background: red;
}

.Nav.Link {
    background: red;
}

.selected {
    background-color: #f0f0f0;
    border-color: #c0c0c0;
    color: #666;
}


.navbar-toggler {
    border: none;
}

.navbar-toggler.collapsed {
    border: none;
}

.navbar-toggler.collapsed {
    box-shadow: none;
    ;
}


.navbar-toggler {
    padding: 10px 20px !important;
    font-size: 18px !important;
    border: none !important;
}

.navbar-toggler:focus {
    text-decoration: none !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.offcanvas-close-icon::before {
    /* add your own custom content */
    content: "Close";
    font-size: 16px;
    font-weight: bold;
}

.offcanvas-close-icon::after {
    /* hide the default "X" content */
    content: "";
    font-size: 0;
}

.NavLink__active {
    /* font-weight: bold !important; */
    background-color: #FFC43D;
    border-radius: .5rem;
    color: black !important;
    padding: 10px 16px 10px 16px !important;
}

.offcanvas-body .navbar-nav .nav-link {
    padding: 10px !important;
}

.navbar {
    position: fixed;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    margin: auto;
    top: 0;
    position: fixed !important;
    top: 0 !important;
}