
html {
	/* position: relative; */

	min-height: 100vh;
}

body {
	/* margin-bottom: 160px !important; Height of the footer */
	padding: 0;
}

footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	/* height: 160px; Set the fixed height of the footer here */
}

/* Override Bootstrap Styling */

.btn-primary {
	background-color: #025fce !important;
}

.e-toast-container .e-toast .e-toast-message .e-toast-content {
	color: #000;
	font-size: 13px;
	font-weight: normal;
}
.e-toast-container .e-toast {
	background-color: whitesmoke;
}

.e-toast-container .e-toast .e-toast-message .e-toast-title {
	color: black;
	font-size: 18px;
	font-weight: bold;
}



/*
html {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

body {
	/* margin-bottom: 160px !important; Height of the footer */
  /* margin: 0;
	padding: 0;
} */

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	color: white;
  }
footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	/* height: 160px; Set the fixed height of the footer here */
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito'), url(./fonts/NunitoSans-Regular.ttf) format('truetype');
}

*{
	scrollbar-width: thin;
}

/* Scrollbar Styling */
/* Thin scrollbars */
::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 10px;
  }
  
  /* Thumb */
  ::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #6c757d;
  }