.footer {
	background-color: #1d1d1d;
	bottom: 0;
	width: 100%;
	max-height: 3rem;
	margin-top: 0%;
	padding-top: 0%;
}

/* footer{
	position: static;
} */

.title {
	color: #fff;
}

.description {
	color: #fff;
}

.footerlink,
.footerlink:hover {
	color: #fff;
}
.label {
	text-decoration-color: white;
	color: white;
	font-size: smaller;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: x-small;
}

.mtop {
	margin-top: 3px;
	text-decoration-color: white;
	color: white;
	font-size: smaller;
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
