/* .e-icon-userlogin:before {
	content: "\e729";
}

.track-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 2px;
	margin: 10px;
}

.centered {
	justify-content: center;
	align-items: center;
} */
/* 
.e-tooltip-wrap.e-popup {
	background-color: #fff;
	border: 2px solid #000;
}

.e-tooltip-wrap {
	border-radius: 4px;
	opacity: 1;
} */

/* .e-headercelldiv {
	background-color: #20e29f;
}

.e-grid .e-gridheader,
.th.e-detailheadercell,
.e-grid .e-headercell {
	color: black;
	background-color: #20e29f;
}

.e-grid .e-gridheader {
	background-color: #20e29f;
	border-bottom-color: #20e29f;
	border-top-color: #20e29f;
	color: rgba(0, 0, 0, 0.54);
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
	background-color: 20e29f;
	border-color: #20e29f;
}

.e-grid .e-altrow {
	background-color: #ecfaf5;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
	background-color: #20e29f;

	border-color: #20e29f;
}

.e-grid .e-gridpager .e-currentitem {
	background-color: #20e29f;
	color: #000;
	border-radius: 3px;
}

.e-input-group.e-control-wrapper .e-input[disabled] {
	-webkit-text-fill-color: black;
} */

/* .e-grid .e-headercelldiv,
.e-grid .e-rowcell,
.e-grid .e-summarycell,
.e-grid .e-row .e-input-group .e-input.e-field {
	font-size: 11px;
} */
/* .export-pdf:before {
	content: "\e240";
	background-color: #20e29f;
	font-size: large;
} */

/* .e-grid .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
} */
/* background-color: #ebf0ee;
	border-left-width: 20px;
	border-style: dotted;
	border-width: 200px;
	margin: 20px; */
/* border-style: solid;
	padding: 10px; */

	
div.App-header > div.container-flex > div.mr-3.ml-3.e-control.e-grid.e-lib.e-gridhover.e-responsive.e-default.e-droppable.e-resize-lines.e-wrap.e-tooltip.e-keyboard {
	border-radius: 10px;
}
div.e-control.e-toolbar.e-lib.e-keyboard{
	border-radius:  10px 10px 0px 0px;
}
#root > div.App-header > div.container-flex > div.mr-3.ml-3.e-control.e-grid.e-lib.e-gridhover.e-responsive.e-default.e-droppable.e-resize-lines.e-wrap.e-tooltip.e-keyboard > div.e-gridpager.e-control.e-pager.e-lib{
	border-radius:  0px 0px 10px 10px;
}

/* for icon of the add document in nested table  */
#gridTripStop > div.e-gridheader.e-lib.e-droppable > div > table > thead > tr > th.e-headercell.e-templatecell:nth-child(2) > div.e-headercelldiv{
	padding: 0 5px 0 5px;
}

/* arrow icon for sorting  */
#gridTripStop > div.e-gridheader.e-lib.e-droppable > div > table > thead > tr > th.e-headercell.e-templatecell:nth-child(2) div.e-sortfilterdiv.e-icons.e-ascending.e-icon-ascending::before,
#gridTripStop > div.e-gridheader.e-lib.e-droppable > div > table > thead > tr > th.e-headercell.e-templatecell:nth-child(2) div.e-sortfilterdiv.e-icons.e-descending.e-icon-descending::before{
	margin-left: 11px;
}

/* for footer and content having extra space in grid stop dialogue */
#gridTripStop_dialogEdit_wrapper div.e-dlg-content#gridTripStop_dialogEdit_wrapper_dialog-content,
#gridTripStop_dialogEdit_wrapper > div.e-footer-content{
	padding: 0px;
}

/* for file drag and drop input boxes  */
div.e-upload.e-control-wrapper.e-lib.e-keyboard > div.e-file-select-wrap{
	padding: 12px;
}

/* the extraa border in the header of the grid */
div.e-gridheader.e-lib.e-droppable > div > table > thead > tr > th > div.e-rhandler.e-rcursor{
	border: none;
}
/* border right for right before the scroollbar */
#trip-search-table > div.e-gridheader.e-lib.e-droppable > div.e-headercontent{
	border-right: none;
}

/* loader css only for appointment table  */
.e-refresh {
	animation: spin-animation 0.5s infinite;
	display: inline-block;
}
@keyframes spin-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

.scheduled-stop-table > div.e-gridheader.e-lib.e-droppable > div > table > thead > tr > th:nth-child(15) > div.e-filtermenudiv.e-icons.e-icon-filter{
	display: none;
}
